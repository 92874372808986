import React, { FC } from "react";

import Header from "../partials/Header";
import Footer from "../partials/Footer";
import Seo from "../partials/Seo";

const Security: FC = () => {
  return (
    <>
      <Seo title="Security" />

      <div className="flex flex-col min-h-screen overflow-hidden">
        {/*  Site header */}
        <Header />

        {/*  Page content */}
        <main className="flex-grow">
          <section className="relative">
            <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
              <div className="pt-32 pb-12 md:pt-40 md:pb-20">
                {/* Page header */}
                <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                  <h1 className="h1 mb-4" data-aos="fade-up">
                    Security
                  </h1>

                  <p
                    className="text-xl text-gray-400"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    FAQ
                  </p>
                </div>

                <article className="mt-10 mx-auto prose prose-dark lg:prose-xl">
                  <h2>Why do you think that Wigwam is safe?</h2>

                  <p>We consider Wigwam safe for several reasons.</p>

                  <p>
                    The first point is a team. The core developer of the Wigwam
                    is Sergiy Pashenko, who has a lot of experience in
                    developing web3, wallets, and web extensions. And also, he
                    is the creator of the Temple Wallet. The number 1 wallet in
                    the Tezos ecosystem with two well Security Audits. More
                    about the team you may{" "}
                    <ExternalLink href="https://wigwam.app/about">
                      read here
                    </ExternalLink>
                    .
                  </p>

                  <p>
                    Secondly, Wigwam uses the best and most time-tested
                    technologies for security. It uses the core for data
                    encryption from the popular{" "}
                    <ExternalLink href="https://keepass.info/">
                      password manager
                    </ExternalLink>
                    , which has already been audited.
                  </p>

                  <p>
                    However, despite our confidence, we consider the possibility
                    of having potential vulnerabilities. For this reason, we
                    plan to conduct an external security audit that will be
                    shared with our community. At the moment, we strongly advise
                    you to use test tokens and work in testnets only for test
                    purposes. Follow us on{" "}
                    <ExternalLink href="https://twitter.com/wigwam_app">
                      Twitter
                    </ExternalLink>{" "}
                    or{" "}
                    <ExternalLink href="https://t.me/wigwamapp">
                      Telegram
                    </ExternalLink>{" "}
                    to track updates related to Wigwam audits and security.
                  </p>

                  <h2>Have you conducted a security audit already?</h2>

                  <p>
                    We have performed several internal security audits, and now,
                    we are considering passing an external security audit.
                    Meanwhile, the application is at the Beta testing stage. We
                    strongly advise our beta testers to use only test tokens and
                    test networks or use hardware wallets like Ledger Nano (in
                    this case, only Ledger is responsible for your private keys,
                    and Wigwam provides only the interface).
                  </p>

                  <h2>
                    Why do you claim that Wigwam is open source, but it's no
                    code on Github?
                  </h2>

                  <p>
                    We stopped delivering new code to the public repository
                    after the first of January. The purpose of this is to
                    protect Wigwam against potential scammers and hackers until
                    we get proof of its security from a third party (Public
                    Security Audit). We think this action enhances wallet
                    security because attackers also do not have access to the
                    source code. It is much more difficult or almost impossible
                    for them to find potential vulnerabilities.
                  </p>

                  <p>
                    We're not moving away from the Open Source policy. The
                    wallet code will be publicly available after the Public
                    Security Audit release. So far, the application is at the
                    Private Beta testing stage, and all operations should be
                    conducted only in testnets.
                  </p>

                  <h2>How does Wigwam interact with Metamask?</h2>

                  <p>
                    Wigwam does NOT interact with Metamask. It is only sensitive
                    to the "Connect to MetaMask" or "Connect to Wallet" button
                    on decentralized application websites. Wigwam and MetaMask
                    are both browser extension. Browser extensions are like apps
                    on your smartphone. They don't have access to each other.
                    And they work on their own.
                  </p>

                  <p>
                    That's why Wigwam has no access to your funds in MetaMask or
                    any other wallets and also has no access to control the
                    other applications! You may find technical details of how
                    Wigwam and Metamask interact with each other in{" "}
                    <ExternalLink href="https://wigwamapp.medium.com/how-vigvam-wallet-may-be-connected-to-any-dapp-with-the-aid-of-metamask-b688f9757184">
                      this article
                    </ExternalLink>
                    .
                  </p>

                  <hr />

                  <p>
                    Do you have any other questions? Ask them on our{" "}
                    <ExternalLink href="https://t.me/wigwamapp">
                      telegram
                    </ExternalLink>{" "}
                    channel.
                  </p>
                </article>
              </div>
            </div>
          </section>
        </main>

        {/*  Site footer */}
        <Footer />
      </div>
    </>
  );
};

export default Security;

const ExternalLink: FC<{ href: string }> = ({ href, children }) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);
